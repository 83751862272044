import { axiosInstanceV1, getReqConfig } from './axiosInstance';

import { parseResponse } from './helper';

const BASE = '/auth';

export function login_req(phone, password) {
  return parseResponse(
    axiosInstanceV1.post(`${BASE}/login`, {
      phone,
      password,
    })
  );
}

export function register_req(phone, password, confirmPassword, birthDate) {
  return parseResponse(
    axiosInstanceV1.post(`${BASE}/Register`, {
      phone,
      password,
      confirmPassword,
      birthDate,
    })
  );
}

export function getToken_req(refreshToken) {
  return parseResponse(
    axiosInstanceV1.post(`${BASE}/refresh-token`, {
      refreshToken,
    })
  );
}

export function verifyRegistration_req(token, code) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/VerifyRegistration`,
      { token, code },
      getReqConfig()
    )
  );
}
